import { all } from 'redux-saga/effects'
import { flowSaga } from '@stackhouseos/flow-client'

const context = require.context('../', true, /containers\/[\s\S]*?\/saga.js$/)
const sagas = context.keys().map(filename => context(filename).default)

export default function* root() {
  yield all([
      ...sagas.map(saga => saga()),
      flowSaga(),
    ])
}