import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loading from 'unioneofferte-core/lib/components/Loading'
import { makeSelectCampaign } from '../../containers/Home/selectors'

import ComingSoon from '../ComingSoon'
import Expired from '../Expired'

function Page({ children }) {
  const campaign = useSelector(makeSelectCampaign)
  const dispatch = useDispatch()

  const { isComingSoon, isExpired } = campaign || {}

  useEffect(() => {
    dispatch({ type: 'FETCH_CAMPAIGN' })
  }, [dispatch])

  if (isComingSoon) {
    return <ComingSoon />
  }

  if (isExpired) {
    return <Expired />
  }

  return campaign ? children : <Loading />
}

export default Page
