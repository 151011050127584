"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stripHtml = stripHtml;
exports.convertDataURIToBinary = convertDataURIToBinary;
exports.composePath = exports.getThemeSize = exports.getThemeColor = exports.delay = exports.getPageName = void 0;

var _lodash = require("lodash");

var _theme = require("./theme");

var getPageName = function getPageName(pageNum) {
  return pageNum === 1 ? 'Prima pagina' : "Pagina ".concat(pageNum);
};

exports.getPageName = getPageName;

function stripHtml(html) {
  var tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}

var delay = function delay(ms) {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve();
    }, ms);
  });
};

exports.delay = delay;

var getThemeColor = function getThemeColor(type) {
  return (0, _lodash.get)(_theme.theme, ['colors', type]);
};

exports.getThemeColor = getThemeColor;

var getThemeSize = function getThemeSize(size) {
  return (0, _lodash.isFinite)(size) ? "".concat(size, "px") : _theme.theme.fontSizes[size];
};

exports.getThemeSize = getThemeSize;

var replaceMatchWith = function replaceMatchWith(params) {
  return function (match) {
    var paramId = (0, _lodash.trimStart)(match, ':');
    return params[paramId];
  };
};

var composePath = function composePath(path, params) {
  return (0, _lodash.replace)(path, /:[^/]*/g, replaceMatchWith(params));
};

exports.composePath = composePath;

function convertDataURIToBinary(dataURI) {
  var BASE64_MARKER = ';base64,';
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for (var i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }

  return array;
}