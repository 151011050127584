"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Icons = exports.Infos = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _theme = require("../../../lib/utils/theme");

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

var Infos = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  display: flex;\n  padding: 30px 20px;\n  z-index: 2;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: center;\n\n  ", "\n    > img {\n      z-index: 2;\n    }\n"])), function (_ref) {
  var backgroundColor = _ref.backgroundColor;
  return backgroundColor || 'transparent';
}, _theme.media.maxWidth('xs')(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n    flex-direction:column;\n    align-items:center;\n  "]))));

exports.Infos = Infos;

var Icons = _styledComponents["default"].div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  z-index: 2;\n  ", "\n  > * {\n    color: white;\n    font-size: 30px;\n    padding: 0 5px;\n  }\n"])), _theme.media.maxWidth('xs')(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2["default"])(["\n  margin-top:20px;\n"]))));

exports.Icons = Icons;