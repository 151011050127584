import React, { useEffect, useState } from 'react'
import Text from 'unioneofferte-core/lib/components/Text'
import Button from 'unioneofferte-core/lib/components/Button'

import { InfoPrice, ItemContainer, ListItemContainer, Main } from './styled'
import { Link } from '@reach/router'
import { CheckOutlined } from '@ant-design/icons'
import axios from 'axios'

const Item = React.memo(({ item, campaignId, buttonTitle }) => {
  const { popular, _id: offerId } = item
  const hasLimit = Number(item?.limitCountOrders) > 0
  const [loading, setLoading] = useState(hasLimit)
  const [disabled, setDisabled] = useState(hasLimit)


  useEffect(() => {
    if (hasLimit) {
      const [, , , slug] = window.location.href.split('/')
      axios.get(`/api/campaign/${slug}/${campaignId}/${offerId}`).then(({ data }) => {
        const { numOrders } = data
        setDisabled(numOrders >= Number(item.limitCountOrders))
        setLoading(false)
      })
    }
  }, [item, hasLimit, offerId, campaignId])

  return (
    <ItemContainer className={popular && 'middle'}>
      <div className='flex-spacer'>
        <InfoPrice>
          <Text
            align='center'
            size={20}
            fontWeight='700'
            type={popular && 'white'}
          >
            {item.title}
          </Text>
          <div className='description'>
            <Text type={popular && 'white'} align='center' light size='small'>
              {item.description}
            </Text>
          </div>
          <div className='price'>
            <Text
              align='center'
              size={55}
              fontWeight='500'
              type={popular && 'white'}
            >
              {item.price
                ? parseFloat(item.price).toFixed(2).replace('.', ',')
                : '0'}
            </Text>
            <div>
              <Text fontWeight='500' type={popular && 'white'}>
                €
              </Text>
              <Text type={popular && 'white'} fontWeight='400'>
                {item.period}
              </Text>
            </div>
          </div>
        </InfoPrice>
        {item.products.map((product, index) => (
          <div key={`option-${index}`} className='options'>
            <CheckOutlined style={{ color: popular && 'white' }} />
            <Text type={popular && 'white'} fontWeight='400'>
              {product.name}
            </Text>
          </div>
        ))}
      </div>
      <div style={{textAlign: 'center', marginTop: 20}}>
        {
          (loading || disabled) ? (
            <Button
              outline
              disabled
              loading={loading}
              bold={false}
              title={loading ? 'Attendi' : 'Terminato'}
              color={popular && 'secondary'}
              variant={popular ? 'white' : 'primary'}
            />
          ) : (
            <Link to={`payment/${campaignId}/${item._id}`}>
              <Button
                outline
                disabled={disabled}
                bold={false}
                title={buttonTitle}
                color={popular && 'secondary'}
                variant={popular ? 'white' : 'primary'}
              />
            </Link>
          )
        }
      </div>

    </ItemContainer>
  )
})

const ListItem = ({ value, listItemRef }) => {
  const { prices = [], priceInfo = {}, _id } = value
  const pricesActive = prices.filter((r) => r.active)

  return priceInfo.active ? (
    <Main ref={listItemRef}>
      {priceInfo.title ? (
        <Text
          variant='white'
          align='center'
          fontWeight='300'
          size={30}
          text={priceInfo.title}
        />
      ) : null}
      {priceInfo.description ? (
        <Text
          variant='white'
          align='center'
          className='main-description'
          size={20}
          fontWeight='300'
          text={priceInfo.description}
        />
      ) : null}

      {pricesActive.length > 0 ? (
        <ListItemContainer>
          {pricesActive.map((item, index) => {
            return (
              <Item
                key={index}
                buttonTitle={priceInfo.buttonTitle}
                item={item}
                campaignId={_id}
              />
            )
          })}
        </ListItemContainer>
      ) : null}
    </Main>
  ) : null
}
export default React.memo(ListItem)
