import Box from '@stackhouseos/box-core'

import Saga from 'unioneofferte-core/lib/components/Saga'
// import StepActions from 'unioneofferte-core/lib/components/StepActions'
// import StepContainer from 'unioneofferte-core/lib/components/StepContainer'
import FormInput from 'unioneofferte-core/lib/components/Input/FormInput'
import FormButton from 'unioneofferte-core/lib/components/Button/FormButton'
import FormSelect from 'unioneofferte-core/lib/components/Select/FormSelect'
// import List from 'unioneofferte-core/lib/components/ListControl'
import EditableList from 'unioneofferte-core/lib/components/EditableList'
// import ListHeader from 'unioneofferte-core/lib/components/ListControl/ListControlHeader'
import Row from 'unioneofferte-core/lib/components/Row'
// import CronScheduler from 'unioneofferte-core/lib/components/CronScheduler'
import Switch from 'unioneofferte-core/lib/components/Switch'
import Alert from 'unioneofferte-core/lib/components/Alert'
import Spacer from 'unioneofferte-core/lib/components/Spacer'
import FormTextArea from 'unioneofferte-core/lib/components/Input/FormTextArea'
import Text from 'unioneofferte-core/lib/components/Text'
import PageRightSidebar from 'unioneofferte-core/lib/components/Layout/PageRightSidebar'
import RadioList from 'unioneofferte-core/lib/components/RadioList'
import ResultOperation from 'unioneofferte-core/lib/components/Result'
import Button from 'unioneofferte-core/lib/components/Button'
import Checkbox from 'unioneofferte-core/lib/components/Checkbox'
import RiepilogoItem from 'unioneofferte-core/lib/components/RiepilogoItem'
import PaymentRiepilogo from '../components/PaymentRiepilogo'
import CardContainer from 'unioneofferte-core/lib/components/CardContainer'

Box.extendControls({
  Row,
  Saga,
  FormInput,
  FormButton,
  FormSelect,
  // StepActions,
  // StepContainer,
  EditableList,
  // ListHeader,
  // List,
  // CronScheduler,
  Switch,
  Alert,
  Spacer,
  ResultOperation,
  FormTextArea,
  Text,
  PageRightSidebar,
  RadioList,
  Button,
  Checkbox,
  RiepilogoItem,
  PaymentRiepilogo,
  CardContainer
})
