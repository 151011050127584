import { all, put, takeEvery } from 'redux-saga/effects'
import { actionUpdate } from '@stackhouseos/box-core'
import { updateStepData } from '@stackhouseos/flow-client';
import axios from 'axios'
import MD5 from 'md5'

export const BASE_URL = 'https://webapi.unionesarda.it/api/user'

const DEVICE_ID = 'xxx'
const PLATFORM = 'web'

function* handleLogin({ payload }) {
    const { flowName, reducerName, value } = payload
    const { email, password } = value

    try {
        if (!email || !password) throw new Error('no-data')
        const res = yield axios.post(`${BASE_URL}/login`, {
            username: email,
            password: MD5(password).toString(),
            app_name: 'ABB',
            device_id: DEVICE_ID,
            platform: PLATFORM
        })

        yield put(updateStepData(flowName, 'error', undefined))
        yield put(updateStepData(flowName, 'success', true))
        yield put(actionUpdate(flowName, 'user', res.data))

    } catch (error) {
        console.log("-> >>> error", error)
        yield put(updateStepData(flowName, 'error', error.message))
    } finally {
        yield put(actionUpdate('payment', 'login.password', undefined))
        yield put({ type: 'flow/next', payload: { flowName, reducerName } })
    }
}


function* handleSignUp({ payload }) {
    const { flowName, reducerName, value } = payload
    const { email, password } = value

    try {
        console.log('handleSignUp')
        if (!email || !password) throw new Error('no-data')

        const res = yield axios.post(`${BASE_URL}/signup`, {
            Mail: email,
            Password: password && MD5(password).toString(),
            PrivacyPolicy1: true, //@todo quale privacy?
            PrivacyPolicy2: true, //@todo
            ShouldRegistrate: true,
            app_name: 'ABB',
            device_id: DEVICE_ID,
            platform: PLATFORM
        })

        yield put(updateStepData(flowName, 'error', undefined))
        yield put(updateStepData(flowName, 'success', true))
        yield put(actionUpdate(flowName, 'user', res.data))

    } catch (error) {
        yield put(updateStepData(flowName, 'error', error.response?.data?.Message || error.message))
    } finally {
        yield put({ type: 'flow/next', payload: { flowName, reducerName } })
    }
}


function* handleRecovery({ payload }) {
    const { flowName, reducerName } = payload

    try {
        console.log('handleRecovery')
        yield put(updateStepData(flowName, 'success', true))

    } catch (error) {
        console.log("-> error", error)
        yield put(updateStepData(flowName, 'error', error.message))
    } finally {
        yield put({ type: 'flow/next', payload: { flowName, reducerName } })
    }
}



export default function* rootSaga() {
    yield all([
        takeEvery('LOGIN_US', handleLogin),
        takeEvery('SIGN_UP_US', handleSignUp),
        takeEvery('LOGIN_US_RECOVERY', handleRecovery),
    ])
}
