import { Link } from '@reach/router'
import React from 'react'
import Button from 'unioneofferte-core/lib/components/Button'
import Text from 'unioneofferte-core/lib/components/Text'

import { FooterText, Main } from './styled'

const rightReservedText = `2021 L'Unione Sarda S.p.A. Tutti i diritti riservati. É vietata la riproduzione, anche parziale e con qualsiasi mezzo, di tutti i materiali del sito. | Indirizzo della Sede Legale: Piazzetta L'Unione Sarda nr. 24 | Capitale sociale 11.400.000,00 i.v. | Codice Fiscale ed iscrizione presso l'Ufficio Registro Imprese di Cagliari 01687830925 (P.I. 02544190925) | REA: CA-136248`

const Footer = () => {
  return (
    <Main>
      <a href='https://www.unionesarda.it/Default/PrivacyPolicy'>
        <Button
          variant='link'
          size={14}
          color='white'
          bold={false}
          title='Cookie e Privacy policy'
        />
      </a>
      <FooterText>
        <Text align='center' size={14} text={rightReservedText} />
      </FooterText>
    </Main>
  )
}
export default Footer
