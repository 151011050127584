import styled from 'styled-components'
import { media } from 'unioneofferte-core/lib/utils/theme'

export const BackgroundContainer = styled.div`
  position: relative;
  height: 60vh;
  min-height: 400px;
  overflow: hidden;
  background: url(${({ background }) => background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &:after {
    content: '';
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .triangle {
    position: absolute;
    bottom: 0;
    display: inline-block;
    width: 0;
    z-index: 2;
    height: 0;
    border-style: solid;
  }

  .left {
    left: 0;
    border-width: 15vh 0 0 50vw;
    border-color: transparent transparent transparent white;
    ${media.maxWidth('sm')`
      border-width: 7vh 0 0 50vw;
    `}
  }

  .right {
    right: 0;
    border-width: 0 0 15vh 50vw;
    border-color: transparent transparent white transparent;
    ${media.maxWidth('sm')`
      border-width: 0 0 7vh 50vw;
    `}
  }
`

export const Title = styled.div`
  position: absolute;
  width: 100%;
  bottom: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 2;

  .title {
    font-size: 5vw;
    ${media.maxWidth('md')`
      font-size: 8vw;
  `}
  }

  > Button {
    margin-top: 10px;
  }
`
