"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FullScreen = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject;

var FullScreen = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  position: ", ";\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: flex;\n  z-index: 9999;\n  background-color: ", ";\n  > * {\n    margin: auto;\n  }\n"])), function (_ref) {
  var absolute = _ref.absolute;
  return absolute ? 'absolute' : 'fixed';
}, function (_ref2) {
  var bgOpacity = _ref2.bgOpacity;
  return "rgba(255, 255, 255, ".concat(bgOpacity || 0, ")");
});

exports.FullScreen = FullScreen;