import styled from 'styled-components'
import { media } from 'unioneofferte-core/lib/utils/theme'

export const Infos = styled.div`
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'transparent'};
  display: flex;
  padding: 30px 20px;
  z-index: 2;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  ${media.maxWidth('xs')`
    flex-direction:column;
    align-items:center;
  `}
  > img {
    z-index: 2;
  }
`

export const Icons = styled.div`
  display: flex;
  z-index: 2;
  ${media.maxWidth('xs')`
  margin-top:20px;
`}
  > * {
    color: white;
    font-size: 30px;
    padding: 0 5px;
  }
`
