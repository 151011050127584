import { isFinite, get, replace, trimStart } from 'lodash'
import { theme } from './theme'

export const getPageName = (pageNum) => pageNum === 1 ? 'Prima pagina' : `Pagina ${pageNum}`

export function stripHtml(html) {
  var tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

export const delay = (ms) => new Promise(resolve => {
  setTimeout(() => {
    resolve()
  }, ms)
})
export const getThemeColor = (type) => get(theme, ['colors', type])

export const getThemeSize = (size) => isFinite(size) ? `${size}px` : theme.fontSizes[size]

const replaceMatchWith = params => match => {
  const paramId = trimStart(match, ':')
  return params[paramId]
}

export const composePath = (path, params) => replace(path, /:[^/]*/g, replaceMatchWith(params))


export function convertDataURIToBinary(dataURI) {
  const BASE64_MARKER = ';base64,'
  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
  const base64 = dataURI.substring(base64Index)
  const raw = window.atob(base64)
  const rawLength = raw.length
  const array = new Uint8Array(new ArrayBuffer(rawLength))

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i)
  }

  return array
}
