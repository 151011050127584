"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RowDeleteWrapper = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject;

var RowDeleteWrapper = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  margin-top: 24px;\n  display: flex;\n  align-items: center;\n  \n  .delete-icon {\n    font-size: 20px;\n    cursor: pointer;\n    margin-left: auto;\n  }\n"])));

exports.RowDeleteWrapper = RowDeleteWrapper;