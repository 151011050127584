import styled from 'styled-components'
import { media } from 'unioneofferte-core/lib/utils/theme'

export const Main = styled.div`
  margin 25px 0;
  display: flex;
  flex:1;
  padding: 60px;
  justify-content: center;
  flex-direction: column;
  ${media.maxWidth('md')`
  flex-direction:column;
  align-items:center;
`}
  .main-description {
    margin: 20px 0;
  }
`
export const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 20px;

  .icon {
    > * {
      font-size: 30px;
      color: #1890ff;
    }
  }

  ${media.maxWidth('md')`
  flex-direction:column;
  align-items:center;
`}
`

export const ItemContainer = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
  margin: 0 25px;
  justify-content: center;
  align-items: center;
  ${media.maxWidth('md')`
  max-width: 435px;
  margin: 2em 0;
  width:100%;
`}
  .title {
    margin: 10px;
  }
`
