"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Main = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _theme = require("unioneofferte-core/lib/utils/theme");

var _templateObject, _templateObject2;

var Main = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  padding: 30px;\n  border: 1px solid #dddddd;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  box-shadow: 0px 13px 19px rgb(0 0 0 / 7%);\n  border-radius: 20px;\n  ", "\n\n \n"])), _theme.media.maxWidth('md')(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n  padding: 20px;\n  "]))));

exports.Main = Main;