import React from 'react'
import {
  ToolFilled,
  StarFilled,
  BulbFilled,
  LikeFilled,
  BellFilled,
  EditFilled,
  HeartFilled,
  ShoppingFilled,
  PlusCircleFilled,
  ThunderboltFilled,
  NotificationFilled,
  PushpinFilled,
  TwitterCircleFilled,
  FacebookFilled,
  MailFilled,
  MobileFilled,
  EnvironmentFilled,
  SlidersFilled,
  FundFilled,
  PieChartFilled,
  FireFilled,
  HomeFilled,
  CloudFilled,
  RocketFilled,
  MessageFilled,
  InstagramFilled,
  ToolOutlined,
  StarOutlined,
  BulbOutlined,
  LikeOutlined,
  FundOutlined,
  EditOutlined,
  BellOutlined,
  FireOutlined,
  HomeOutlined,
  MailOutlined,
  PushpinOutlined,
  CloudOutlined,
  HeartOutlined,
  PieChartOutlined,
  MobileOutlined,
  RocketOutlined,
  MessageOutlined,
  PlusCircleOutlined,
  SlidersOutlined,
  ShoppingOutlined,
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  EnvironmentOutlined,
  ThunderboltOutlined,
  NotificationOutlined
} from '@ant-design/icons'

export const icons = {
  tool: <ToolFilled />,
  star: <StarFilled />,
  bulb: <BulbFilled />,
  like: <LikeFilled />,
  fund: <FundFilled />,
  edit: <EditFilled />,
  bell: <BellFilled />,
  fire: <FireFilled />,
  home: <HomeFilled />,
  mail: <MailFilled />,
  pin: <PushpinFilled />,
  cloud: <CloudFilled />,
  heart: <HeartFilled />,
  pie: <PieChartFilled />,
  mobile: <MobileFilled />,
  rocket: <RocketFilled />,
  message: <MessageFilled />,
  plus: <PlusCircleFilled />,
  sliders: <SlidersFilled />,
  shopping: <ShoppingFilled />,
  facebook: <FacebookFilled />,
  instagram: <InstagramFilled />,
  twitter: <TwitterCircleFilled />,
  environment: <EnvironmentFilled />,
  thunderbolt: <ThunderboltFilled />,
  notification: <NotificationFilled />,
  toolOutlined: <ToolOutlined />,
  starOutlined: <StarOutlined />,
  bulbOutlined: <BulbOutlined />,
  likeOutlined: <LikeOutlined />,
  fundOutlined: <FundOutlined />,
  editOutlined: <EditOutlined />,
  bellOutlined: <BellOutlined />,
  fireOutlined: <FireOutlined />,
  homeOutlined: <HomeOutlined />,
  mailOutlined: <MailOutlined />,
  pinOutlined: <PushpinOutlined />,
  cloudOutlined: <CloudOutlined />,
  heartOutlined: <HeartOutlined />,
  pieOutlined: <PieChartOutlined />,
  mobileOutlined: <MobileOutlined />,
  rocketOutlined: <RocketOutlined />,
  messageOutlined: <MessageOutlined />,
  plusOutlined: <PlusCircleOutlined />,
  slidersOutlined: <SlidersOutlined />,
  shoppingOutlined: <ShoppingOutlined />,
  facebookOutlined: <FacebookOutlined />,
  instagramOutlined: <InstagramOutlined />,
  twitterOutlined: <TwitterOutlined />,
  environmentOutlined: <EnvironmentOutlined />,
  thunderboltOutlined: <ThunderboltOutlined />,
  notificationOutlined: <NotificationOutlined />
}
