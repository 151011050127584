"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RightContent = exports.Content = exports.StartPageContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _theme = require("unioneofferte-core/lib/utils/theme");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

var StartPageContainer = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: column;\n  background-color: #fff;\n\n  ", "\n"])), _theme.media.minWidth('md')(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n    flex-direction: row;\n  "]))));

exports.StartPageContainer = StartPageContainer;

var Content = _styledComponents["default"].div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n  position: relative;\n  width: 100%;\n  flex: 1;\n  flex-direction: column;\n  justify-content: center;\n  padding: 50px 30px;\n\n  ", "\n"])), function (_ref) {
  var compact = _ref.compact;
  return compact ? "padding:30px;" : _theme.media.minWidth('md')(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2["default"])(["\n      padding: 50px 60px;\n      width: 80%;\n      "])));
});

exports.Content = Content;

var RightContent = _styledComponents["default"].div(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2["default"])(["\n  min-height: inherit;\n  background-color: #f8fbfc;\n  flex: 1;\n  flex-direction: column;\n  justify-content: center;\n  min-height: inherit;\n  padding: 50px 30px;\n\n  ", "\n"])), function (_ref2) {
  var compact = _ref2.compact;
  return compact ? "padding:30px" : _theme.media.minWidth('md')(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2["default"])(["\n      width: 20%;\n      min-height: inherit;\n      "])));
});

exports.RightContent = RightContent;