import React, { Suspense } from 'react'
import { Router } from '@reach/router'
import { Devtools } from '@stackhouseos/flow-client'
import GlobalStyle from 'unioneofferte-core/lib/utils/GlobalStyle'
import Loading from 'unioneofferte-core/lib/components/Loading'
import Home from './pages/Home'
import Payment from './pages/Payment'

import './utils/initBox'
import Success from './pages/Success'
import Canceled from './pages/Canceled'
import ErrorPage from './pages/ErrorPage'
import Intro from './pages/Intro'
import Page from './pages/Page'
import 'dayjs/locale/it'

Devtools({ port: 8771 /* host: '192.168.1.234' */ })

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Intro path={'/'} />
        <Page path={'/:slug'}>
          <Home path={'/'} />
          <Payment path={'payment/:campaignId/:offerId'} />
          <Success path={'success'} />
          <Canceled path={'canceled'} />
        </Page>
        <ErrorPage path={'error'} />
      </Router>
      <GlobalStyle />
    </Suspense>
  )
}

export default App
