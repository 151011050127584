import { createSelector } from 'reselect'
import _get from 'lodash/get'

const campaignSelect = (state) => state.app

export const makeSelectCampaign = createSelector(campaignSelect, (substate) =>
  _get(substate, 'data')
)

export const makeSelectValidAt = createSelector(campaignSelect, (substate) =>
  _get(substate, 'data.validAt')
)
