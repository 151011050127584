import { createSlice } from '@reduxjs/toolkit'
import _set from 'lodash/set'

const slice = createSlice({
  name: 'payment',
  initialState: {},
  reducers: {
    setSelectedPromo: (state, action) => {
      state.data = action.payload
    },
    clearAll: (state) => {
      state.data = { }
    },
  },
  extraReducers: {
    '@box/payment/update': (state, action) => _set(state, action.payload.id, action.payload.value)
  }
})

export const actions = slice.actions
export default slice



