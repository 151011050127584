"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledResult = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _antd = require("antd");

var _templateObject;

var StyledResult = (0, _styledComponents["default"])(_antd.Result)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n"])));
exports.StyledResult = StyledResult;