import React from 'react'
import { Infos } from './styled'
import { getThemeColor } from 'unioneofferte-core/src/utils/helpers'

const HeaderBar = ({ backgroundColor, logo = '/assets/logo-us-white.svg' }) => {
  return (
    <Infos backgroundColor={getThemeColor(backgroundColor)}>
      <img
        aria-disabled
        alt='logo-us-white'
        src={logo}
        style={{ maxWidth: 200, maxHeight: 100 }}
      />
    </Infos>
  )
}
export default HeaderBar
