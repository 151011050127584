import React, { useCallback } from 'react'

import Text from 'unioneofferte-core/lib/components/Text'

import { BackgroundContainer, Title } from './styled'

import HeaderBar from '../../HeaderBar'
import Button from 'unioneofferte-core/lib/components/Button'
import smoothscroll from 'smoothscroll-polyfill'

const defaultLogo = '/assets/logo-us-white.svg'
const defaultBg = '/assets/unsplash.jpg'

const prefix = process.env.REACT_APP_S3_URL

const Header = ({ value, listItemRef }) => {
  const { headLine, buttonTitle, image, background, logo, priceInfo } =
    value || {}

  const handleClick = useCallback(
    () => {
      listItemRef.current.scrollIntoView({ behavior: 'smooth' })
      smoothscroll.polyfill()
    },
    []
  )
  
  return (
    <BackgroundContainer
      image={image}
      background={
        background
          ? `${prefix}/campaigns/${background}/background.jpeg`
          : defaultBg
      }
    >
      <div className='triangle left'></div>
      <div className='triangle right'></div>
      <HeaderBar
        logo={logo ? `${prefix}/campaigns/${logo}/logo.png` : defaultLogo}
      />
      <Title>
        <Text className='title' align='center' fontWeight='700' type={'white'}>
          {headLine}
        </Text>
        {priceInfo.active && (
          <Button
            bold={false}
            width={200}
            onClick={handleClick}
            variant='primaryDark'
            title={buttonTitle}
          />
        )}
      </Title>
    </BackgroundContainer>
  )
}
export default Header
