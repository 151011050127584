"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InputWrapper = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _theme = require("../../utils/theme");

var _templateObject;

var InputWrapper = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  .input-element {\n    border-radius: ", ";\n    padding-right: 16px;\n    padding-left: 16px;\n    ", "\n    ", "\n    ", "\n    ", "\n\n    .ant-input-prefix {\n      margin-right: 8px;\n    }\n  }\n"])), _theme.theme.borderRadius, function (_ref) {
  var margin = _ref.margin;
  return (margin === null || margin === void 0 ? void 0 : margin.top) && "margin-top: ".concat(margin.top, ";");
}, function (_ref2) {
  var margin = _ref2.margin;
  return (margin === null || margin === void 0 ? void 0 : margin.right) && "margin-right: ".concat(margin.right, ";");
}, function (_ref3) {
  var margin = _ref3.margin;
  return (margin === null || margin === void 0 ? void 0 : margin.bottom) && "margin-bottom: ".concat(margin.bottom, ";");
}, function (_ref4) {
  var margin = _ref4.margin;
  return (margin === null || margin === void 0 ? void 0 : margin.left) && "margin-left: ".concat(margin.left, ";");
});

exports.InputWrapper = InputWrapper;