export const theme = {
  colors: {
    primary: '#115191',
    primaryLight: '#3F77A9',
    primaryDark: '#05376A',
    secondary: '#2490FF',
    tertiary: '#374754',
    success: '#43C600',
    danger: 'red',
    darkBlue: '#252B42',
    textDark: '#404040',
    textLight: '#909090',
    text: '#292929',
    textSpecial: '#17133E',
    white: '#fefefe'
  },
  fontSizes: {
    extraSmall: '12px',
    small: '14px',
    medium: '16px',
    large: '24px',
    extraLarge: '32px'
  },
  borderRadius: '20px'
}

export const breakpoints = {
  xl: 1920,
  lg: 1366,
  md: 1024,
  sm: 768,
  xs: 480
}

export const media = {
  minWidth: (key) => (style) =>
    `@media screen and (min-width: ${breakpoints[key]}px) { ${style} }`,
  maxWidth: (key) => (style) =>
    `@media screen and (max-width: ${breakpoints[key] - 1}px) { ${style} }`
}
