import { createSelector } from 'reselect'
import _get from 'lodash/get'

const paymentSelect = (state) => state.payment

export const makeSelectPayment = createSelector(paymentSelect, (substate) =>
  _get(substate, 'data', {})
)

export const makeSelectPaymentBilling = createSelector(paymentSelect, (substate) =>
  _get(substate, 'billing', {})
)

export const getUserIdUS = createSelector(paymentSelect, (substate) =>
  _get(substate, 'user.userId')
)
