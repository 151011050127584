import { all, takeLatest, put, select } from 'redux-saga/effects'
import { actions } from './reducer'
import axios from 'axios'
import { actionUpdate } from '@stackhouseos/box-core'
import { getUserIdUS, makeSelectPayment, makeSelectPaymentBilling } from './selectors'
import { makeSelectCampaign } from '../Home/selectors'
import { find } from 'lodash'

function* handleSetSelectedPromo({ payload }) {
  const { offerId, campaignId } = payload
  const campaign = yield select(makeSelectCampaign)
  const { prices } = campaign

  const item = find(prices, (rec) => rec._id === offerId)

  const products = item.products.map((item) => item.name).join(', ')

  yield put(
    actions.setSelectedPromo({
      offerId,
      campaignId,
      title: item.title,
      price: item.price,
      interval: item.interval,
      //stripeId: item.stripeId,
      products: products,
      billing: {
        typology: 'private'
      }
    })
  )
}

function* paymentProcess({ payload }) {
  const { flowName, reducerName } = payload

  const [, , , slug] = window.location.href.split('/')

  const paymentData = yield select(makeSelectPayment)
  const billing = yield select(makeSelectPaymentBilling)
  const userId = yield select(getUserIdUS)

  let err
  let data

  try {
    const response = yield axios.post('/api/order', { 
      slug, 
      userId, 
      ...paymentData,
      billing
     })
     
    data = response.data

    yield put(actionUpdate('payment', 'success', true))
  } catch (error) {
    err = error
    console.log('error', error)
    yield put(actionUpdate('payment', 'success', false))
  } finally {
    if (!err) {
      window.location.replace(data.url)
    } else {
      yield put({ type: 'flow/next', payload: { flowName, reducerName } })
    }
  }
}

export default function* homeSaga() {
  yield all([
    yield takeLatest('SET_SELECTED_PROMO', handleSetSelectedPromo),
    yield takeLatest('PAYMENT_PROCESS', paymentProcess)
  ])
}
