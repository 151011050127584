import styled from 'styled-components'

export const StyledRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
  
`
export const StyledLabel= styled.div`

  
`
export const StyledText = styled.div`
    max-width: 300px;
    text-align: right;
`