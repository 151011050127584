import React from 'react'
import Footer from '../../components/Footer'
import { Container, StyledDiv } from '../ComingSoon/styled'

function Intro() {
  return (
    <Container>
      <StyledDiv>
        <img src={'/assets/logo_us.png'} />
      </StyledDiv>
      <Footer />
    </Container>
  )
}
export default Intro
