import React from 'react'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import Text from 'unioneofferte-core/lib/components/Text'
import Loading from 'unioneofferte-core/lib/components/Loading'
import HeaderBar from '../../components/HeaderBar'
import Footer from '../../components/Footer'

import { makeSelectValidAt } from '../../containers/Home/selectors'
import { StyledDiv, Container } from './styled'

function Expired() {
  const validAt = useSelector(makeSelectValidAt)

  return validAt ? (
    <Container>
      <HeaderBar backgroundColor='darkBlue' />
      <StyledDiv>
        <Text align='center' size={30}>
          La campagna è finita il giorno {''}
          {dayjs(validAt.end).locale('it').format('dddd DD MMMM YYYY')}
        </Text>
      </StyledDiv>
      <Footer />
    </Container>
  ) : (
    <Loading />
  )
}
export default Expired
