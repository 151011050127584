import styled from 'styled-components'
import { media } from 'unioneofferte-core/lib/utils/theme'

export const Main = styled.div`
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.maxWidth('md')`
  padding: 20px;
  `}

  .description {
    margin: 25px 0;
  }
`
export const Box = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  ${media.maxWidth('md')`
  flex:1;
  flex-direction: column;
  `}
`
export const FormContainer = styled.div`
  padding: 30px 70px;
  border: 1px solid #dddddd;
  display: flex;
  flex: 1;
  min-height: 400px;
  width: 40vw;
  min-width: 300px;
  flex-direction: column;
  box-shadow: 0px 13px 19px rgba(0, 0, 0, 0.07);
  border-radius: 20px;

  ${media.maxWidth('md')`
  margin: 20px;
  width: 100%;
  padding: 30px 30px;
  `}
`
export const ContactsContainer = styled.div`
  display: flex;
  margin: 30px;
  flex: 1;
  align-items: center;
  flex-direction: column;
  ${media.maxWidth('md')`
  margin: 30px;
  `}
  .contact {
    margin: 10px;
  }
`
export const Infos = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-items: center;

  ${media.maxWidth('xs')`
    margin: 10px 0;
  `}

  .item {
    display: flex;
    flex: 1;
    flex-direction: column;
    color: #2091f9;
    align-items: center;
    margin-bottom: 20px;
    .icon {
      font-size: 30px;
    }
  }
`
export const StyledIcon = styled.div`
  font-size: 20px;
  color: #1890ff;
`
