import styled from 'styled-components'
import { media } from 'unioneofferte-core/lib/utils/theme'

export const Main = styled.div`
  display: flex;
  background-color: #252b42;
  align-items: center;
  padding: 60px;
  flex-direction: column;
  .main-description {
    margin-top: 20px;
    margin-bottom: 2em;
  }
`
export const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${media.maxWidth('md')`
  flex-direction: column;
`}
  .middle {
    background-color: #1890ff;
  }
`
export const ItemContainer = styled.div`
  display: flex;
  padding: 40px;
  flex: 1;
  margin: 0 25px;
  border-radius: 10px;
  background-color: #fff;
  flex-direction: column;
  max-width: 500px;
  
  ${media.maxWidth('md')`
    max-width: 435px;
    margin: 2em 0;
    width: 100%;
  `}

  .options {
    display: flex;
    margin: 10px 0 0 30px;
    justify-content: flex-start;
    align-items:center;

    .anticon{
      margin-right: 10px;
    }

  }

  > a {
    text-align:center;
    margin-top: 30px;
  }

  .flex-spacer{
    flex:1;
  }
`

export const InfoPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .description {
    display: flex;
  }
  .price {
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: row;
    > div {
      display: flex;
      margin-left: 10px;
      flex-direction: column;
    }
  }
`
