import React from 'react'
import Flow from '@stackhouseos/flow-client'
import flow from './flow.json'
import HeaderBar from 'unioneofferte-core/lib/components/HeaderBar'

function ErrorPage() {
  return (
    <>
      <HeaderBar
        backgroundColor='darkBlue'
        logo={'/assets/logo-us-white.svg'}
      />
      <Flow
        destroyOnUnmount
        name={flow.name}
        elements={flow.elements}
        reducerName={'app.data'}
        extraContext={{ showErrors: true }}
      />
    </>
  )
}
export default ErrorPage
