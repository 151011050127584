import React from 'react'

import flow from './flow.json'
import Flow from '@stackhouseos/flow-client/lib/components/Flow'

import Text from 'unioneofferte-core/lib/components/Text'

import {
  ContactsContainer,
  FormContainer,
  StyledIcon,
  Infos,
  Main,
  Box
} from './styled'
import { icons } from '../../config'

const ContactUs = ({ campaign }) => {
  const { contact = {} } = campaign || {}
  const { title = '', description = '', infos, active } = contact

  return active ? (
    <Main>
      <Text text={title} align='center' variant='darkBlue' size={40} />
      <Text
        align='center'
        size={28}
        fontWeight='300'
        variant='tertiary'
        className='description'
        text={description}
      />
      <Box>
        <FormContainer>
          <Flow
            destroyOnUnmount
            name={flow.name}
            elements={flow.elements}
            reducerName={'contact.fields'}
          />
        </FormContainer>
        <ContactsContainer>
          <Infos>
            {infos.map((el, index) => (
              <div key={index} className='item'>
                {el.icon && <StyledIcon>{icons[el.icon]}</StyledIcon>}
                <Text align='center' className='contact'>
                  {el.title}
                </Text>
              </div>
            ))}
          </Infos>
        </ContactsContainer>
      </Box>
    </Main>
  ) : null
}

export default ContactUs
