import { createSlice } from '@reduxjs/toolkit';
import _set from 'lodash/set';

export const initialState = {
  modals: {},
  routes: {}
}

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    openModal: (state, action) => {
      _set(state.modals, action.payload.id, true)
    },
    setRoutes: (state, action) => {
      state.routes = action.payload.routes
    },
  },
  extraReducers: {
    '@box/app/update': (state, action) => _set(state, action.payload.id, action.payload.value),
  },
});

export const actions = slice.actions;
export default slice;
