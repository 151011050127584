import React from 'react'
import Text from 'unioneofferte-core/lib/components/Text'
import { icons } from '../../config'

import { InfoContainer, ItemContainer, Main } from './styled'

const Item = ({ icon, title, description }) => (
  <ItemContainer>
    <div className='icon'>{icons[icon]}</div>
    <Text
      className='title'
      variant='darkBlue'
      align='center'
      text={title}
      fontWeight='700'
      size={20}
    />
    <Text
      variant='textLight'
      align='center'
      text={description}
      bold={false}
      size={16}
    />
  </ItemContainer>
)

const InfoBody = ({ value }) => {
  const { infos = [], info = {} } = value
  const { description = '', title = '', active } = info

  return active ? (
    <Main>
      <Text
        variant='darkBlue'
        align='center'
        text={title}
        bold={false}
        size={40}
      />
      <Text
        className='main-description'
        variant='textLight'
        align='center'
        text={description}
        bold={false}
        size={28}
      />
      <InfoContainer>
        {infos.map((item, index) => {
          return <Item key={index} {...item} />
        })}
      </InfoContainer>
    </Main>
  ) : null
}
export default InfoBody
