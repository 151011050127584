"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledText = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _theme = require("../../utils/theme");

var _templateObject;

var StyledText = _styledComponents["default"].span(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  font-size: ", ";\n  line-height: ", ";\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"])), function (_ref) {
  var color = _ref.color;
  return color || _theme.theme.colors.textDark;
}, function (_ref2) {
  var size = _ref2.size;
  return size || _theme.theme.fontSizes.medium;
}, function (_ref3) {
  var lineHeight = _ref3.lineHeight;
  return lineHeight || 1.6;
}, function (_ref4) {
  var serif = _ref4.serif;
  return serif && 'font-family: serif;';
}, function (_ref5) {
  var bold = _ref5.bold;
  return bold && 'font-weight: bold;';
}, function (_ref6) {
  var fontWeight = _ref6.fontWeight;
  return fontWeight && "font-weight: ".concat(fontWeight, ";");
}, function (_ref7) {
  var align = _ref7.align;
  return align && "text-align: ".concat(align, ";");
}, function (_ref8) {
  var block = _ref8.block;
  return block && 'display: block;';
}, function (_ref9) {
  var gutterTop = _ref9.gutterTop;
  return gutterTop && "margin-top: ".concat(gutterTop, "px;");
}, function (_ref10) {
  var gutterRight = _ref10.gutterRight;
  return gutterRight && "margin-right: ".concat(gutterRight, "px;");
}, function (_ref11) {
  var gutterBottom = _ref11.gutterBottom;
  return gutterBottom && "margin-bottom: ".concat(gutterBottom, "px;");
}, function (_ref12) {
  var gutterLeft = _ref12.gutterLeft;
  return gutterLeft && "margin-left: ".concat(gutterLeft, "px;");
});

exports.StyledText = StyledText;