import { createSlice } from '@reduxjs/toolkit'
import _set from 'lodash/set'

const slice = createSlice({
  name: 'contact',
  initialState: {},
  reducers: {
    setSelectedPromo: (state, action) => {
      state.riepilogo = action.payload
    },
    clearAll: (state) => {
      state.data = {}
    }
  },
  extraReducers: {
    '@box/contact/update': (state, action) =>
      _set(state, action.payload.id, action.payload.value)
  }
})

export const actions = slice.actions
export default slice
