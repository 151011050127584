import { all, put, spawn, takeLatest } from 'redux-saga/effects'
import { actionUpdate } from '@stackhouseos/box-core'
import axios from 'axios'
import { navigate } from '@reach/router'

function* fetchProducts({ payload }) {
  const [, , , slug] = window.location.href.split('/')

  try {
    const response = yield axios.get('/api/campaign/' + slug)
    yield put(actionUpdate('app', 'data', response.data))

    if (payload?.params)
      yield put({ type: 'SET_SELECTED_PROMO', payload: payload.params })
  } catch (error) {
    console.log('TCL: function*fetchProducts -> error', error)
    yield spawn(navigate, '/error')
  }
}

export default function* homeSaga() {
  yield all([yield takeLatest('FETCH_CAMPAIGN', fetchProducts)])
}
