import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import ListItem from '../../components/Preview/ListOffers'
import InfoBody from '../../components/InfoBody'
import ContactUs from '../../components/ContactUs'
import Footer from '../../components/Footer'

import { makeSelectCampaign } from '../../containers/Home/selectors'
import Header from '../../components/Preview/Header'

const Home = () => {
  const campaign = useSelector(makeSelectCampaign)
  const ref = useRef(null)

  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Header listItemRef={ref} value={campaign} />
      <InfoBody value={campaign} />
      <ListItem listItemRef={ref} value={campaign} />
      <ContactUs campaign={campaign} />
      <Footer />
    </div>
  )
}
export default Home
