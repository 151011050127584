import React, { useEffect } from 'react'
import Flow from '@stackhouseos/flow-client'
import flow from './flow.json'
import { useDispatch } from 'react-redux'
import HeaderBar from 'unioneofferte-core/lib/components/HeaderBar'
import { useParams } from '@reach/router'
import Footer from '../../components/Footer'
import { Main } from './styled'

function Payment() {
  const dispatch = useDispatch()
  const params = useParams()

  useEffect(() => {
    dispatch({ type: 'FETCH_CAMPAIGN', payload: { params } })
  }, [dispatch, params])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Main>
      <HeaderBar backgroundColor='darkBlue' logo={'/assets/logo-us-white.svg'} />
      <Flow
        destroyOnUnmount
        name={flow.name}
        elements={flow.elements}
        reducerName={'payment'}
        extraContext={{ showErrors: true }}
      />
      <Footer />
    </Main>
  )
}
export default Payment
