import { combineReducers } from '@reduxjs/toolkit'
import { flowReducer } from '@stackhouseos/flow-client'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import payment from '../containers/Payment/reducer'

const context = require.context('../', true, /containers\/[\s\S]*?\/reducer.js$/)
const reducers = context.keys().map(filename => context(filename).default)

const paymentPersistConfig = {
  key: 'payment',
  storage: storage,
  whitelist: ['billing'],
  stateReconciler: autoMergeLevel2
}

export default combineReducers({
  flow: flowReducer,
  ...reducers.reduce((acc, inc) => ({ ...acc, [inc.name]: inc.reducer }), {}),
  payment: persistReducer(paymentPersistConfig, payment.reducer),
})
