import styled from 'styled-components'
import { media } from 'unioneofferte-core/lib/utils/theme'

export const Main = styled.div`
padding 48px 30px;
background-color: #000;
display: flex;
flex-direction: column;
align-items:center;

`
export const FooterText = styled.div`
  margin: 0 auto;
  display: flex;
  padding: 4px 0;
  max-width: 960px;
  border-top: 1px solid #434343;
`
