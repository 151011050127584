"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Styled = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject;

var Styled = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  height:", ";\n  width:", ";\n  display: ", ";\n  flex: ", ";\n"])), function (_ref) {
  var size = _ref.size;
  return size !== 'expanded' && "".concat(size, "px");
}, function (_ref2) {
  var size = _ref2.size;
  return size !== 'expanded' && "".concat(size / 2, "px");
}, function (_ref3) {
  var size = _ref3.size;
  return size === 'expanded' && "flex";
}, function (_ref4) {
  var size = _ref4.size;
  return size === 'expanded' && 1;
});

exports.Styled = Styled;