"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = require("styled-components");

var _templateObject;

var GlobalStyle = (0, _styledComponents.createGlobalStyle)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  h1, h2, h3, h4, h5, h6, p {\n    margin: 0;\n  }\n  .ant-drawer {\n    z-index: 9999;\n  }\n\n  .logo {\n    float: left;\n    width: 120px;\n    height: 31px;\n    margin: 16px 24px 16px 0;\n    background: rgba(255, 255, 255, 0.1);\n    border-radius:6px;\n    align-items:center;\n    justify-content:center;\n    display:flex;\n    color: white;\n\n  }\n  .site-layout .site-layout-background {\n    background: #fff;\n  }\n"])));
var _default = GlobalStyle;
exports["default"] = _default;