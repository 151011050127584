import { all, put, takeLatest, select } from 'redux-saga/effects'
import { actionUpdate } from '@stackhouseos/box-core'
import axios from 'axios'
import { makeSelectContact } from './selectors'

function* contactSupport({ payload }) {
  const { flowName, reducerName } = payload
  const contact = yield select(makeSelectContact)

  try {
    yield axios.post('/api/contact', contact)
    yield put(actionUpdate('contact', 'success', true))
  } catch (error) {
    yield put(actionUpdate('contact', 'success', false))
    console.log('error', error)
  } finally {
    yield put({ type: 'flow/next', payload: { flowName, reducerName } })
  }
}

export default function* homeSaga() {
  yield all([yield takeLatest('CONTACT_SUPPORT', contactSupport)])
}
