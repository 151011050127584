import React from 'react'
import { StyledRow, StyledLabel, StyledText } from './styled'
import Text from 'unioneofferte-core/lib/components/Text'


const ListItem = ({
  label,
  text,
  type,
  size = 'medium',
  bold
}) => {
  return (
    <StyledRow>
      <StyledLabel>
        <Text bold type="primary" size="medium">{label}</Text>
      </StyledLabel>
      <StyledText>
        <Text type={type} size={size} bold={bold}>{text}</Text>
      </StyledText>
    </StyledRow >
  )
}

const List = ({
  item
}) => {
  const price = item?.price ? parseFloat(item.price).toFixed(2).replace('.', ',') : '0'
  return (
    item ? <>
      <ListItem label="Offerta" text={item.title} />
      <ListItem label="Prodotti" text={item.products} />
      <ListItem label="Prezzo" type='secondary' size='large' text={`${price}€ ${item.interval || ''}`} />
    </> : null
  )
}

export default React.memo(List)
